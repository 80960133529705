import React, { useEffect, useState } from 'react';

import Header from '../../../components/header-bar/index.jsx';
import imageArrowDown from '../../../images/icons/angle-arrow-down-black-s.svg';
import { useAPI } from '../../../utils/api';
import store, { actions, getters } from '../../../redux/store';
import { navigate } from 'gatsby';
import { showLoading, dismissLoading } from '../../../utils/loading';
import { useSelector } from 'react-redux';
import { callAppFunc } from '../../../utils/jsbridge/index.js';

const RefoundDetail = ({ RefundCode }) => {
  const api = useAPI();

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const otherAction = () => {
    navigate('/order/info/refund', {
      replace: false,
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [refund, setRefund] = useState({});

  useEffect(() => {
    showLoading();
    api
      .getRefundDetail(RefundCode)
      .then((res) => {
        dismissLoading();
        console.log(res);
        setRefund(res);
        store.dispatch(actions.setRefundDetail(res));
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="refound-detail">
        <div className="header">
          <Header
            title={'退訂退款詳情'}
            goBack={() => {
              // navigate(-1);
              callAppFunc('backHome', {});
            }}
          />
        </div>
        <div className="title">
          <div>
            <span>訂單編號</span>
            <span>{refund.shiprefund_code}</span>
          </div>
          <div>
            {refund.apply_refund_date} {refund.apply_refund_time}退款成功
          </div>
        </div>
        <div className="detail-block order-no">
          <span>訂單編號</span>
          <span>{refund.order_no}</span>
        </div>

        {refund && refund.products && (
          <div className="detail-block product-name">
            <div>{refund.products.first[0].item_name}</div>
            <div>
              <div>{refund.products.first[0].qty}</div>
              <span>退訂金額 ${refund.products.first[0].subtotal}</span>
            </div>
          </div>
        )}

        <div className={`hide-zone ${isOpen ? 'open' : ''}`}>
          {refund &&
            refund.products &&
            refund.products.other_else.map((product, index) => (
              <div className="detail-block product-name" key="{index}">
                {index === 0 && <div>{product.qty}</div>}
                <div>{product.item_name}</div>
                <div>
                  <div>{product.qty}</div>
                  <span>退訂金額 ${product.subtotal}</span>
                </div>
              </div>
            ))}
        </div>
        <div
          className={`shore-more ${isOpen ? 'open' : ''}`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <span>展開更多</span>
          <img src={imageArrowDown} alt="arrow-down" />
        </div>
        <div className="total-refound-price">
          <span>總計已退款</span>
          <span>${refund.refund_amount}</span>
        </div>
        <div className="refound-content">
          <div className="title">退訂方式</div>
          <div className="content">{refund.refund_method}</div>
          {/* <div className="title">退訂原因</div>
          <div className="content">{refund.reason_for_unsubscription}</div> */}
          <div className="button" onClick={otherAction}>
            其他資訊
          </div>
        </div>
      </div>
      <style jsx>{`
        .refound-detail {
          min-height: 100vh;
          padding: 55px 0 30px 0;
          box-sizing: border-box;
          .header {
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
          }
          & > .title {
            background: rgba(95, 210, 218, 0.25);
            border: 1px solid #5fd2da;
            box-sizing: border-box;
            padding: 13px 16px 10px 16px;
            div {
              font-size: 14px;
              line-height: 20px;

              color: #3b3516;
            }
            div:nth-child(1) {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            div:nth-child(2) {
              margin-top: 8px;
            }
          }
          .detail-block {
            width: calc(100% - 32px);
            padding: 16px 0;
            margin: 0 auto;
            box-sizing: border-box;
            border-bottom: 1px solid #f0f0f0;
            font-size: 16px;
            line-height: 23px;

            color: #333333;
            &.order-no {
              display: flex;
              justify-content: space-between;
              span:nth-child(2) {
                font-weight: bold;
              }
            }
            &.product-name {
              div:nth-child(1) {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              div:nth-child(2) {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
                div {
                  background: #f2f2f2;
                  padding: 0 7px;
                }
              }
            }
          }
          .hide-zone {
            max-height: 0;
            overflow: hidden;
            transition: all 1s ease;
            &.open {
              max-height: 2000px;
            }
          }
          .shore-more {
            padding: 8px 0 32px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 16px;
              line-height: 23px;
              color: #333333;
            }
            img {
              margin-left: 13px;
              transition: transform 0.5s ease;
            }
            &.open {
              img {
                transform: rotate(180deg);
              }
            }
          }
          .total-refound-price {
            padding: 26px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f2f2f2;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            color: #333333;
          }
          .refound-content {
            padding: 0 16px;
            .title {
              margin-top: 16px;
              font-weight: bold;
              font-size: 18px;
              line-height: 26px;
              color: #333333;
            }
            .content {
              margin-top: 8px;
              font-size: 16px;
              line-height: 23px;
              color: #333333;
            }
            .button {
              margin-top: 32px;
              background: #ffffff;
              border: 1px solid #5fd2da;
              box-sizing: border-box;
              border-radius: 40px;
              font-weight: bold;
              font-size: 20px;
              line-height: 29px;
              text-align: center;
              color: #5fd2da;
              padding: 15px;
            }
          }
        }
      `}</style>
    </>
  );
};

export default RefoundDetail;
